.text
    color: var(--color-light)

    &_color_primary
        color: var(--color-primary)

    &_color_accent
        color: var(--color-accent)

    &_color_danger
        color: var(--color-danger)
