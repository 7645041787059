@import '../../../styles/mixins/tablet'
@import '../../../styles/mixins/desktop'

.movies-page
    &__search-form
        padding: 80px 14px 50px

        @include tablet
            padding-left: 0
            padding-right: 0

    &__loader
        display: grid
        place-content: center

    &__movies
        display: flex
        flex-direction: column
        gap: 34px
        flex-wrap: wrap
        justify-content: center
        padding-bottom: 50px

        @include tablet
            flex-direction: row
            gap: 30px

        @include tablet
            gap: 20px

    &__buttons
        display: flex
        justify-content: center
        padding-bottom: 80px

    &__button-more
        width: 240px
        height: 36px
        padding: 0

        @include tablet
            width: 320px
