.link
    color: var(--color-light)
    text-decoration: none
    transition: outline .2s ease-out

    &:hover
        text-decoration: underline

    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 1px
        border-radius: 3px

    &_color_primary
        color: var(--color-primary)

    &_color_accent
        color: var(--color-accent)

    &_color_danger
        color: var(--color-danger)
