@import '../../../styles/mixins/tablet'
@import '../../../styles/mixins/desktop'
@import '../../../styles/mixins/touch-device'

.movie
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%

    @include tablet
        width: 339px

    @include desktop
        width: 270px

    &__cover-image
        width: 100%

        @include tablet
            width: 339px

        @include desktop
            width: 270px

    &__cover-link
        display: flex

    &__title-wrapper
        display: flex
        align-items: center
        padding-top: 5px
        justify-content: space-between
        gap: 10px

    &__title
        font-size: 12px
        font-weight: var(--font-weight-bold)

        @include tablet
            font-size: 13px

    &__body
        padding-bottom: 25px

    &__cover
        overflow: hidden

        @include tablet
            height: 190px

        @include desktop
            height: 151px

    &__footer
        font-size: 10px
        color: var(--color-light--dakren-2)
        border-top: 1px solid var(--color-dark--lighten-5)
        padding-top: 6px

        @include tablet
            font-size: 11px

    &__delete-button,
    &__like-button
        display: grid
        place-content: center
        width: 24px
        height: 24px
        border-radius: 50%
        flex-shrink: 0
        transition: background-color .2s ease-out

    &__like-button
        display: block

    &__delete-button
        & svg
            transform: scale(40%)

    &__button_loading,
    &__button_loading[disabled]
        display: block
        cursor: auto
        background: transparent

        &:hover
            background: transparent

    &__button-loader
        width: 100% !important
        height: 100% !important

    &__like-button_liked
        color: var(--color-danger)
