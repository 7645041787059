@import '../../../../styles/mixins/tablet'

.menu
    display: flex
    padding: 25px 0
    background: var(--color-dark--lighten-1)
    justify-content: center
    gap: 40px

    &__link
        font-size: 11px
        font-weight: var(--font-weight-bold)
        text-decoration: underline

        @include tablet
            font-size: 13px

        &:hover
            text-decoration: none
