@import 'mixins/tablet'

.form
    display: flex
    flex-direction: column
    gap: 20px
    width: 100%
    flex: 1

    @include tablet
        width: 396px
        min-height: 440px
        align-self: center

    &__field
        display: flex
        flex-direction: column
        gap: 10px

    &__label
        color: var(--color-light--dakren-2)
        font-size: 10px

        @include tablet
            font-size: 12px

    &__input
        background: var(--color-dark--lighten-2)
        color: var(--color-light)
        font-size: 13px
        border: none
        border-radius: 8px
        padding: 15px
        transition: background-color .2s ease-out, outline .2s ease-out, opacity .2s ease-out

        @include tablet
            font-size: 14px

        &[disabled]
            opacity: 0.6

    &__field_has-error &__input
        color: var(--color-danger)

    &__info-text
        color: var(--color-light--dakren-2)
        font-size: 10px

        @include tablet
            font-size: 12px

    &__error-text
        color: var(--color-danger)
        font-size: 10px

        @include tablet
            font-size: 12px

    &__buttons
        display: flex
        flex-direction: column
        margin-top: auto
        gap: 14px

    &__buttons-text
        color: var(--color-light--dakren-2)
        font-size: 12px

        @include tablet
            font-size: 14px
            text-align: center
