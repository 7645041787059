@import root
@import mobile-only
@import desktop-only
@import form
@import link
@import text
@import no-wrap

body
    height: 100%
    font-family: var(--font-family-base)
    font-weight: var(--font-weight-base)
    background: var(--color-dark)
    font-size: 14px
    line-height: normal
    color: var(--color-light)
    overflow-y: hidden

html, body, #root
    height: 100%

a
    color: var(--color-light)
    text-decoration: none
    transition: outline .2s ease-out
    outline: 2px solid transparent
    outline-offset: 1px

    &:hover
        text-decoration: underline

    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 1px
        border-radius: 3px

input,
input[type="search"]
    transition: outline .2s ease-out
    outline: 2px solid transparent
    outline-offset: 2px

    &:focus,
    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 2px

button
    transition: outline .2s ease-out
    outline: 2px solid transparent
    outline-offset: 2px

    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 2px
