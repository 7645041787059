@import '../../../styles/mixins/tablet'
@import '../../../styles/mixins/desktop'

.drawer
    position: fixed
    width: 100%
    max-width: 520px
    height: 100%
    right: 0
    top: 0
    transition: transform .2s ease-out
    transform: translate3d(100%, 0, 0)
    will-change: transform
    z-index: 10
    background: var(--color-dark)
    overflow-y: auto

    &_opened
        transform: translateZ(0)

    &__close-button
        position: absolute
        right: 20px
        top: 20px

        @include tablet
            right: 36px
            top: 20px

    &__content
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100vh
        padding: 145px 0 0
        box-sizing: border-box
        gap: 50px

        @include tablet
            padding: 160px 0 0

    &__menu
        display: flex
        flex-direction: column
        text-align: center
        gap: 24px
        font-size: 18px
        font-weight: var(--font-weight-bold)

    &__menu-link
        align-self: center

    &__profile-link
        display: flex
        gap: 13px
        align-items: center
        align-self: center
        font-size: 14px
        padding-bottom: 50px

        @include tablet
            padding-bottom: 90px

    &__profile-link-icon
        display: inline-grid
        background: var(--color-dark--lighten-4)
        width: 30px
        height: 30px
        border-radius: 6px
        place-items: center
