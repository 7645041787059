.button
    background: none
    cursor: pointer
    color: var(--color-light)
    border: 1px solid var(--color-dark--lighten-5)
    border-radius: 3px
    box-sizing: border-box
    font-weight: var(--font-weight-bold)
    padding: 15px 30px
    transition: background-color .2s ease-out, outline .2s ease-out, opacity .2s ease-out
    display: grid
    place-content: center

    &:hover
        background: var(--color-dark--lighten-1)

    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 2px

    &_size_medium
        padding: 11px 20px

    &_size_small
        line-height: 16px
        padding: 8px 20px

    &_color_accent
        color: var(--color-dark)
        background: var(--color-accent)
        border: none

        &:hover
            background: var(--color-accent--dakren-1)

    &_color_primary
        background: var(--color-primary)
        border: none

        &:hover
            background: var(--color-primary--dakren-1)

    &_color_dark
        color: var(--color-light)
        background: var(--color-dark--lighten-4)
        border: none

        &:hover
            background: var(--color-dark--lighten-5)

    &_type_icon
        padding: 0
        border: 0

    &[disabled]
        color: var(--color-light)
        background: var(--color-dark--lighten-4)
        border: none
        opacity: 0.6

        &:hover
            background: var(--color-dark--lighten-4)
