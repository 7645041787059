@import '../../../styles/mixins/tablet'

.switch
    display: flex
    gap: 12px
    align-items: center
    border-radius: 20px
    transition: outline .2s ease-out
    outline: 2px solid transparent
    outline-offset: 2px

    &:focus-visible
        outline: 2px solid var(--color-primary)
        outline-offset: 2px

    &__indicator
        display: block
        width: 36px
        height: 20px
        border-radius: 20px
        background: var(--color-light--dakren-2)
        padding: 2px
        box-sizing: border-box
        cursor: pointer
        position: relative
        transition: background .2s ease-out

    &_active &__indicator
        background: var(--color-accent)

    &__pin
        display: block
        width: 16px
        height: 16px
        border-radius: 50%
        background: var(--color-light)
        position: absolute
        transition: left .2s ease-out
        left: 2px

    &_active &__pin
        left: 18px

    &__text
        user-select: none
        cursor: pointer
        font-size: 11px

        @include tablet
            font-size: 13px
