@import '../../../styles/mixins/tablet'
@import '../../../styles/mixins/desktop'

.profile-page
    display: flex
    flex-direction: column
    align-items: center
    padding: 0 20px 0
    height: 100%

    &__header
        margin: 0
        padding: 70px 0 80px
        font-size: 24px
        font-weight: var(--font-weight-bold)
        text-align: center

        @include tablet
            align-items: center

    &__info
        width: 100%

        @include tablet
            width: 410px

    &__item
        display: flex
        justify-content: space-between
        padding: 16px 0
        border-bottom: 1px solid var(--color-dark--lighten-5)

    &__item:first-child
        padding-top: 0

    &__item:last-child
        padding-bottom: 0
        border-bottom: 0

    &__buttons
        display: flex
        flex-direction: column
        margin-top: auto
        padding-bottom: 40px
        text-align: center
        gap: 16px

        @include tablet
            margin-top: initial
            padding: 180px 0 40px



