@import '../../../styles/mixins/tablet'

.auth-layout
    display: flex
    flex-direction: column
    align-items: center
    padding: 0 30px 0
    height: 100%

    &__logo
        display: flex
        justify-content: center

        @include tablet
            width: 396px
            justify-content: left

    &__logo-link
        display: flex

    &__header
        margin: 0
        padding: 50px 0 80px
        font-size: 24px
        font-weight: var(--font-weight-bold)
        text-align: center

        @include tablet
            width: 396px
            text-align: left
            padding: 40px 0

    &__content
        display: flex
        flex-direction: column
        height: 100%
        width: 100%
        padding: 56px 0 30px

        @include tablet
            display: grid
            align-content: center
            width: 396px
            padding: 0
