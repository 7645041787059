@import '../../../styles/mixins/desktop'

.search-form
    display: flex
    align-items: center
    flex-direction: column
    border-bottom: 1px solid var(--color-dark--lighten-5)

    &__input-wrapper
        position: relative
        width: 100%

    &__submit
        display: grid
        place-content: center
        width: 34px
        height: 34px
        border-radius: 50%
        color: var(--color-light)
        position: absolute
        right: 6px
        top: 6px

    &__input
        background: var(--color-dark--lighten-2)
        color: var(--color-light)
        border: none
        border-radius: 40px
        box-sizing: border-box
        padding: 14px 56px 14px 22px
        font-size: 13px
        line-height: 18px
        width: 100%
        display: block

        &:focus-visible
            outline: 2px solid var(--color-primary)
            outline-offset: 2px

        &::placeholder
            color: var(--color-light--dakren-2)

    &__switch
        padding: 40px 0 50px

        @include desktop
            padding: 40px 0 70px
