// Fonts
$fontFamilyBase: 'Inter', sans-serif
$fontWeightBase: 400
$fontWeightBold: 500

// Colors
$colorDark: #202020
$colorLight: #FFFFFF
$colorPrimary: #4285F4
$colorSecondary: #073042
$colorAccent: #3DDC84
$colorDanger: #EE3465

// Breakpoints
$breakpointTablet: 768px
$breakpointDesktop: 1280px
$maxLayoutWidth: 1600px
